import Vue from 'vue'
import PermissionServiceItem from '../permission-service-item/index.vue'
import objectScan from 'object-scan'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'users-admins-permission-service',
  props: {
    list: {},
    microservice: {
      type: String
    }
  },
  data () {
    return {
      updateRender: true,
      entity: []
    }
  },
  components: {
    PermissionServiceItem
  },
  mounted () {
    this.checkMicroserviceGroups()
  },
  watch: {
    currentItem () {
      if (Object.keys(this.currentItem).length) {
        setTimeout(() => {
          this.checkMicroserviceGroups()
        }, 100)
      }
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'user/permissions',
      currentItem: 'userAdmins/currentStudent',
      userPermissions: 'userAdmins/userPermissions'
    })
  },
  methods: {
    ...mapMutations({
      setCurrentServicePermission: 'userAdmins/SET_CURRENT_SERVICE_PERMISSIONS'
    }),
    checkMicroserviceGroups (val) {
      const find = (data, needle) => objectScan([needle], { rtn: 'value' })(data)
      const elems = this.permissions[this.microservice]
      const selectedElems = this.userPermissions[this.microservice]

      Object.entries(elems).forEach(e => {
        const ids = find(e[1], '**.id')
        if (!ids.length) return
        if (!selectedElems) return
        const found = ids.every(r => selectedElems.includes(r))
        if (found) {
          this.entity.push(e[0])
        } else {
          const index = this.entity.findIndex(e => e === e[0])
          if (index >= 0) {
            Vue.delete(this.entity, index)
          }
        }
      })

      this.updateRender = false
      this.$nextTick(() => {
        this.updateRender = true
      })
    },
    getParentElems (val, name) {
      const find = (data, needle) => objectScan([needle], { rtn: 'value' })(data)
      const ids = find(this.permissions[this.microservice][name], '**.id')
      this.setCurrentServicePermission({
        microservice: this.microservice,
        ids: ids,
        key: val.includes(name) ? 'add' : 'remove'
      })

      this.updateRender = false
      this.$nextTick(() => {
        this.updateRender = true
      })
    }
  }
}
