import { mapActions, mapGetters } from 'vuex'
import validation from '@/mixins/validation'

import Permissions from '../../components/permission-service/index.vue'

export default {
  name: 'users-admins-permissions',
  mixins: [validation],
  data () {
    return {
      tab: '',
      payload: {
        permissionIds: []
      }
    }
  },
  created () {
    !Object.entries(this.permissions).length && this.fetchPermissions()
  },
  components: {
    Permissions
  },
  computed: {
    ...mapGetters({
      permissions: 'user/permissions',
      currentStudent: 'userAdmins/currentStudent',
      userPermissions: 'userAdmins/userPermissions',
      isLoading: 'userAdmins/isLoading'
    })
  },
  methods: {
    ...mapActions({
      fetchPermissions: 'user/GET_PERMISSIONS',
      update: 'userAdmins/UPDATE_PERMISSIONS'
    }),
    submit () {
      const formData = {
        _method: 'patch',
        userId: this.$route.params.id,
        id: []
      }
      Object.values(this.userPermissions).forEach(e => {
        formData.id.push(...e)
      })
      this.update(formData).then(() => {
        this.$toasted.success(this.$t('success_updated'))
      })
    }
  }
}
